<template>
  <div class="w-100 d-flex">
    <div class="chat-content chat-bot info-right p-2 w-100">
      <h5>{{$t('creator.submit_contents')}}</h5>
      <span class="text-box-success">{{$t('creator.submit_contents_text')}}</span>

      <b-form-file 
        v-model="file" 
        accept=".jpeg, .jpg, .png, .mp4, .mov"
        browse-text="Buscar" 
        class="my-2" 
        @input="hasChangeFiles"
        :disabled="generating_preview"
        :placeholder="$t('dashboard.chooseFileChat')"
      >
      </b-form-file>

      <b-row>
        <b-col v-for="(preview, index) in previews" :key="index" class="col-12 py-2 border-bottom d-flex justify-content-between box-previews-draggable">
          <div class="d-flex align-items-center">
            <b-avatar :src="preview.preview" square class="avatar-preview-sample" icon="camera-video"></b-avatar>
            <strong class="ml-1">
              <span v-if="preview.name.length > 0">{{preview.name.slice(0, 30)}} <span v-if="preview.name.length > 30"> ...</span></span>
            </strong>
          </div>
          <b-button variant="flat-secondary" @click="deleteFile(preview)">
            <feather-icon icon="TrashIcon" size="20"></feather-icon>
          </b-button>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-1" variant="light" @click="clearFiles()">{{$t('creator.cancel')}}</b-button>
        <b-button :disabled="files.length === 0 || is_uploading" class="blue-button-next-step" variant="blue-button-next-step" @click="uploadContents()">
          <b-spinner class="spinner-waiting-service" v-if="is_uploading"></b-spinner>
          {{$t('creator.submit')}}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BButton,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import { getThumbnails } from 'video-metadata-thumbnails';
import chat_service from '@/services/chat';
import utils from '@/libs/utils';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'uploadContentsChat',
  components: {
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormFile,
    BSpinner,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previews: [],
      file: null,
      files: [],
      generating_preview: false,
      is_uploading: false,
    }
  },
  created() {
  },
  methods: {
    hasChangeFiles() {
      this.generating_preview = true;
      this.files.push(this.file)
      const urls_blobs = [];
      setTimeout(() => {
        const url_blob = URL.createObjectURL(this.file);
        const unique_id = utils.getUniqueIndex();
        if (!this.file.type.includes('video')) {
          urls_blobs.push({preview: url_blob, name: this.file.name, id: unique_id});
          this.generating_preview = false
          this.$emit('to_bottom')
        } else {
          this.previews.push({preview: 'is_video', name: this.file.name, id: unique_id});
          const thumbnail = getThumbnails(url_blob, {
            quality: 0.6
          });
          thumbnail.then((value) => {
            const url_blob_thubnail = URL.createObjectURL(value[0].blob);
            this.generating_preview = false
            this.$emit('to_bottom')
            this.previews.map((f) => {
              if (f.id === unique_id) f.preview = url_blob_thubnail
            });
          });
        }
        this.previews = this.previews.concat(urls_blobs);
      },200)
    },
    deleteFile(preview) {
      this.files = this.files.filter((item) => item.name !== preview.name);
      this.previews = this.previews.filter((item) => item.preview !== preview.preview);
    },
    uploadContents() {
      this.is_uploading = true;
      this.generating_preview = true
      const form_data = new FormData();
      this.files.forEach((file) => {
        form_data.append('files', file);
      });

      chat_service.uploadContentsChat(this.chat.uuid, form_data).then((response) => {
        if (response?.status === 500) {
          this.is_uploading = false;
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('creator.errorContent'),
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: this.$t('creator.statusRejected')
              }
            })
          })
        } else {
          this.is_uploading = false;
          this.$emit('uploaded_contents')
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('creator.successContentChat'),
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: this.$t('creator.successContentChatText')
              }
            })
          })
        }
      })
    },
    clearFiles() {
      this.files = []
      this.previews = []
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.info-right {
  border-left: 7px solid #55A6C4;
  background: white;
}
.avatar-preview-sample {
  margin-right: 1em;
  width: 45px !important;
  height: 45px !important;
  object-fit: cover !important;
  border-radius: 1em !important;
}
.spinner-waiting-service {
  width: 18px;
  height: 18px;
  margin-right: 0.5em;
}
</style>
