<template>
  <div class="w-100">
    <div class="chat-avatar" v-if="!requesting_revision && !accepting_contents">
      <b-avatar
        size="36"
        class="avatar-border-2 box-shadow-1"
        :src="require('@/assets/images/mascot/dil_small.jpg')"
      />
    </div>
    <div class="chat-body" v-if="!requesting_revision && !accepting_contents">
      <div class="chat-content chat-bot box-success">
        <h5>{{ $t('creator.sellerSubmit') }}</h5>
        <span class="text-box-success">{{ $t('creator.finalResult') }}</span>

        <div>
          <b-button @click="requestRevision()" variant="outline-dark" class="mr-2 mt-1">{{ $t('creator.requestRevision') }}</b-button>
          <b-button @click="acceptContents()" variant="success" class="mt-1">{{ $t('creator.acceptContent') }}</b-button>
        </div>
      </div>
    </div>
    <div v-else-if="requesting_revision" class="chat-content chat-bot info-right">
      <div class="p-1">
        <h5>{{ $t('creator.requestRevision') }}</h5>
        <span class="text-muted" v-if="revisions_madeit !== 0">{{ revisions_madeit }} {{ $t('creator.remaining') }}</span>
        <b-form-textarea
          class="mt-1"
          v-model="revision_text"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <div class="mt-1">
          <b-button @click="cancelRequestRevision()" variant="light" class="mr-2">{{ $t('creator.cancel') }}</b-button>
          <b-button @click="submitRequestRevision()" variant="blue-button" class="blue-button">{{ $t('creator.submit') }}</b-button>
        </div>
      </div>
    </div>
    <div v-else-if="accepting_contents" class="chat-content chat-bot info-right">
      <div class="p-1">
        <h5>{{ $t('creator.acceptContent') }}</h5>
        <span class="text-muted">{{(chat.casting_call) ? $t('creator.byAcceptCasting') : $t('creator.byAccept') }}</span>
        <div class="d-flex align-items-center mt-1">
          
          <b-form-checkbox v-model="accept_contents" size="lg" class="mr-2 check-position"></b-form-checkbox>
          <span class="text-bold-checkbox cursor-pointer ml-2" @click="toggleCheckbox()">
            {{(chat.casting_call) ? $t('creator.agree_content') : $t('creator.agreePay') }}
          </span>
        </div>

        <div class="mt-1">
          <b-button @click="cancelAcceptContents()" variant="light" class="mr-2">{{ $t('creator.cancel') }}</b-button>
          <b-button @click="submitAcceptContents()" class="blue-button-next-step" variant="blue-button-next-step">{{ $t('creator.submit') }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BFormTextarea,
  BFormCheckbox
} from 'bootstrap-vue'
import chat_service from '@/services/chat';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'reviewContentsChat',
  components: {
    BAvatar,
    BButton,
    BFormTextarea,
    BFormCheckbox
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      requesting_revision: false,
      accepting_contents: false,
      accepting_contents_casting: false,
      revision_text: '',
      accept_contents: false
    }
  },
  created() {
  },
  computed: {
    revisions_madeit() {
      return this.chat.messages.filter(i => i.type_message === 'revision').length
    }
  },
  methods: {
    requestRevision() {
      this.requesting_revision = true
    },
    acceptContents() {
      this.accepting_contents = true;
    },
    cancelRequestRevision() {
      this.requesting_revision = false;
    },
    cancelAcceptContents() {
      this.accepting_contents = false;
    },
    submitRequestRevision() {
      chat_service.requestRevisionChat(this.chat.uuid, this.revision_text, 'request-revision').then(() => {
        this.$emit('update_chat')
      })
    },
    submitAcceptContents() {
      if (!this.accept_contents) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('creator.needConfirm'),
            icon: 'AlertCircleIcon',
            variant: 'danger',
          }
        })
      } else if (this.chat?.casting_call?.type_compensation === 'negotiable') {
        chat_service.getCheckoutPitch(this.chat.uuid).then((response) => {
          if (response?.url) {
            window.location.href = response.url;
          }
        })
      } else {
        chat_service.acceptContents(this.chat.uuid, 'accept-contents').then(() => {
          this.$emit('update_chat')
        })
      }

    },
    toggleCheckbox() {
      this.accept_contents = !this.accept_contents
    }
  },
  watch: {
  }
}
</script>

<style>
.check-position {
  position: absolute;
  left: 60px;
}
.box-info {
  width: 100%;
  max-width: 95% !important;
  color: #ADB0B4 !important;
  background-color: #F7F9FB !important;
  border: 2px solid #c8c8c8;
  padding: 5px;
}
.text-box-success {
  color: #506263;
}
.box-success {
  width: 100%;
  max-width: 95% !important;
  color: #4E5B5B !important;
  background-color: #DDF8EE !important;
  border: 2px solid #A9D7C9;
}
.info-right {
  border-left: 7px solid #55A6C4;
  background: white;
}
</style>
