<template>
  <div class="width-initial-parent row m-0">
    <!-- Brand Container -->
    
    <b-col :class="`${is_avaible_to_editing ? 'col-12 col-md-8' : 'col-12'} p-0 mb-1`">
      <div class="d-flex py-1 container-brand-category position-relative">
        <b-avatar icon="shop" :src="img_logo" size="50" class="mr-1"></b-avatar>
        <div class="text-left">
          <h2 class="m-0">{{ $t('creator.workWith') }} {{ casting_call.brand.name }}</h2>
          <p class="m-0 text-type-service cursor-pointer" @click="searchByService">{{type_of_service}}</p>
        </div>
  
        <div class="background-button-edit" @click="$emit('edit_campaign', 'brand')" v-if="is_avaible_to_editing">
          <h5 class="text-white animation-opacity-words">{{$t('creator.editBrand')}}</h5>
        </div>
      </div>
    </b-col>

    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-1" v-if="is_avaible_to_editing">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'brand')">
        <feather-icon icon="Edit2Icon"></feather-icon>
        <strong class="ml-05 avenir-bold">{{$t('creator.brand')}}</strong>
      </div>
    </b-col>
    <!-- --------------- -->

    <!-- Location container -->
    <div v-if="is_avaible_to_editing && (Array.isArray(casting_call.location) && casting_call.location.length > 0)" class="w-100 text-description-preview" :class="`${is_avaible_to_editing ? 'col-12 col-md-8' : 'col-12'} p-0 mb-1`">
      <p class="avenir-medium">
        {{ $t('creator.locationInfluencers')}}: 
        {{ Array.isArray(casting_call.location) ? casting_call.location.join(', ') : casting_call.location }}
      </p>
      <div class="background-button-edit" @click="$emit('edit_campaign', 'location')" v-if="is_avaible_to_editing">
        <h5 class="text-white animation-opacity-words">{{$t('creator.editLocation')}}</h5>
      </div>
    </div>
    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-1" v-if="is_avaible_to_editing && Array.isArray(casting_call.location) && casting_call.location.length > 0">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'location')">
        <feather-icon icon="Edit2Icon"></feather-icon>
        <strong class="ml-05 avenir-bold">{{$t('creator.locationInfluencers')}}</strong>
      </div>
    </b-col>
    <!-- ------------------- -->

    <!-- No location container -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && (!casting_call.location || casting_call.location.length === 0)">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button class="add-option-action blue-version" variant="add-option-action" @click="$emit('edit_campaign', 'location')">
          <h4 class="avenir-bold">{{ $t('creator.locationInfluencers') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.locationInfluencersText') }}</p>
          <div class="box-add-option-absolute blue-box-option avenir-bold">
            {{ $t('creator.addLocation') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit blue-edit-bubble" @click="$emit('edit_campaign', 'description')">
          <div>
            <strong class="avenir-bold mb-1 d-block">{{ $t('creator.locationInfluencers') }}</strong>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ---- -->

    <!-- Description container -->
    <div v-if="casting_call.description" class="w-100 text-description-preview" :class="`${is_avaible_to_editing ? 'col-12 col-md-8' : 'col-12'} p-0 mb-1`">
      <p class="word-break" v-html="convertUrlsToLinks(casting_call.description)">
      </p>
      <div class="background-button-edit" @click="$emit('edit_campaign', 'description')" v-if="is_avaible_to_editing">
        <h5 class="text-white animation-opacity-words">{{$t('creator.editDescription')}}</h5>
      </div>
    </div>
    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-1" v-if="is_avaible_to_editing && casting_call.description">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'description')">
        <feather-icon icon="Edit2Icon"></feather-icon>
        <strong class="ml-05 avenir-bold">{{$t('creator.description')}}</strong>
      </div>
    </b-col>
    <!-- ------------------- -->

    <!-- No Description container -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && !casting_call.description">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button class="add-option-action blue-version" variant="add-option-action" @click="$emit('edit_campaign', 'description')">
          <h4 class="avenir-bold">{{ $t('creator.description') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.specifyDescription') }}</p>
          <div class="box-add-option-absolute blue-box-option avenir-bold">
            {{ $t('creator.addDescription') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit blue-edit-bubble" @click="$emit('edit_campaign', 'description')">
          <div>
            <strong class="avenir-bold mb-1 d-block"><b-icon icon="asterisk" class="mr-05"></b-icon>{{ $t('creator.description') }}</strong>
            <p class="avenir-medium m-0">{{ $t('creator.required') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ---- -->
   
    <!-- platforms or subcategories container-->
    <div class="w-100 text-left" :class="`${is_avaible_to_editing ? 'col-12 col-md-8 d-flex flex-wrap' : 'd-none'} p-0 mb-2`">
      <div class="container-platforms-category position-relative w-100">
        <h6 class="mr-1 mb-1">{{getTitlePlatforms()}}</h6>
        <b-badge pill v-for="(type_work, index) in casting_call.work_types" :key="index" :variant="getBadgeNetwork(type_work)" :class="`${getBadgeNetwork(type_work)} badge-networks mr-1 mb-1`">
          <b-img :src="getIconWhite(type_work)" class="img-network" fluid></b-img>
          {{$t(`creator.service_focus_in_${casting_call.content_type}.options`).find((type) => type.value === type_work).text}}
        </b-badge>
        <div class="background-button-edit" @click="$emit('edit_campaign', 'platforms')" v-if="is_avaible_to_editing">
          <h5 class="text-white animation-opacity-words">{{$t('lists.edit') }} {{ getTitlePlatforms() }}</h5>
        </div>
      </div>
    </div>

    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-2" v-if="is_avaible_to_editing">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'platforms')">
        <feather-icon icon="Edit2Icon"></feather-icon>
        <strong class="ml-05 avenir-bold">{{ getTitlePlatforms() }}</strong>
      </div>
    </b-col>
    <!-- ------------------------------- -->

    <!-- Delivery container -->
    <div 
      class="container-deadline-campaign" 
      :class="`${is_avaible_to_editing ? 'col-12 col-md-8 d-flex' : 'd-none'} p-0 mb-2`"
      v-if="casting_call.date_delivery"
    >
      <div class="" v-if="casting_call.date_delivery"> 
        <h6 class="mr-1 mb-1">{{ $t('creator.dueBy') }}</h6>
        <b-badge pill variant="light-secondary-v2" class="light-secondary-v2 mb-1">
          <feather-icon icon="CalendarIcon"></feather-icon>
          {{getDatev2(new Date(casting_call.date_delivery))}}
        </b-badge>
      </div>

      <div class="background-button-edit" @click="$emit('edit_campaign', 'deadline')" v-if="is_avaible_to_editing">
        <h5 class="text-white animation-opacity-words">{{$t('creator.editDeadline')}}</h5>
      </div>
    </div>

    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-2" v-if="is_avaible_to_editing && casting_call.date_delivery">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'deadline')">
        <feather-icon icon="Edit2Icon"></feather-icon>
        <strong class="ml-05 avenir-bold">{{$t('creator.deadline')}}</strong>
      </div>
    </b-col>
    <!-- ---------------------- -->

    <!-- No Delivery container -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && !casting_call.date_delivery">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button class="add-option-action" variant="add-option-action" @click="$emit('edit_campaign', 'deadline')">
          <h4 class="avenir-bold">{{ $t('creator.deadline') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.noDeadline') }}</p>
          <div class="box-add-option-absolute avenir-bold">
            {{ $t('creator.addDelivery') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit purple-edit-bubble" @click="$emit('edit_campaign', 'deadline')">
          <div>
            <strong class="avenir-bold mb-1 d-block"><b-icon icon="brightness-high" class="mr-05"></b-icon>{{ $t('creator.responseDeadline') }}</strong>
            <p class="avenir-medium m-0">{{ $t('creator.specifyDelivery') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ---- -->

    <!-- Compesation container -->
    <div 
      :class="`${is_avaible_to_editing ? 'col-12 col-md-8' : 'd-none'} p-0 mb-2 container-compensation-campaign`"
      v-if="casting_call.type_compensation"
    >
      <h6 class="mr-1 mb-1 w-100 text-left">{{ $t('creator.compesation') }}</h6>
      <div v-if="casting_call.compensations.length > 0" class="position-relative text-left">
        <b-badge pill varinat="outline-secondary-badge" class="outline-secondary-badge mr-1">
          {{ $t('creator.fixed') }}
        </b-badge>
        <b-badge pill v-for="(compensation, index) in casting_call.compensations" :key="index" variant="badge-compensation" class="badge-compensation mr-1 mb-1">
          <b-icon v-if="getCompensation(compensation.type_compensation).icon" class="custom-badge-class" :icon="getCompensation(compensation.type_compensation).icon"></b-icon>
          <b-img v-else class="icon-svg" :src="IconTypeCompetationGreen(compensation.type_compensation)"></b-img>
          {{ getCompensation(compensation.type_compensation).text2 }}

          <span v-if="casting_call.type_compensation === 'fixed'">
            ($ {{ numberFormat(compensation.amount, true) }} USD) 
          </span>
        </b-badge>
      </div>
      <div v-else class="d-flex">
        <b-badge pill varinat="outline-secondary-badge" class="outline-secondary-badge">
          {{ $t('creator.negotiable') }}
        </b-badge>
      </div>
      <div class="background-button-edit" @click="$emit('edit_campaign', 'compensation')" v-if="is_avaible_to_editing">
        <h5 class="text-white animation-opacity-words">{{$t('creator.editCompensation')}}</h5>
      </div>
    </div>

    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-2" v-if="is_avaible_to_editing && casting_call.type_compensation">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'compensation')">
        <feather-icon icon="Edit2Icon"></feather-icon>
        <strong class="ml-05 avenir-bold">{{$t('creator.compensations')}}</strong>
      </div>
    </b-col>
    <!-- ------------------------- -->

    <!-- No Compensation container -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && !casting_call.type_compensation">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button class="add-option-action blue-version" variant="add-option-action" @click="$emit('edit_campaign', 'compensation')">
          <h4 class="avenir-bold">{{ $t('creator.compensations') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.noCompensation') }}</p>
          <div class="box-add-option-absolute blue-box-option avenir-bold">
            {{ $t('creator.addCompensation') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit blue-edit-bubble" @click="$emit('edit_campaign', 'compensation')">
          <div>
            <strong class="avenir-bold mb-1 d-block"><b-icon icon="asterisk" class="mr-05"></b-icon>{{ $t('creator.compensations') }}</strong>
            <p class="avenir-medium m-0"> {{ $t('creator.compensationsRequired') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ---- -->

    <!-- Visibility container -->
    <div :class="`${is_avaible_to_editing ? 'col-12 col-md-8' : 'd-none'} p-0 mb-1 container-compensation-campaign`">
      <div class="d-flex flex-wrap position-relative">  
        <h6 class="mr-1 mb-1 w-100 text-left">{{ $t('creator.visibilityStep') }}</h6>
        <b-badge pill varinat="outline-secondary-badge"     class="outline-secondary-badge"
        
        >
          {{ $t(`creator.${!casting_call.visibility ? 'public' : casting_call.visibility}`) }}
        </b-badge>
      </div>
      <div class="background-button-edit" @click="$emit('edit_campaign', 'visibility')" v-if="is_avaible_to_editing">
        <h5 class="text-white animation-opacity-words">{{$t('creator.editVisibility')}}</h5>
      </div>
    </div>

    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-1" v-if="is_avaible_to_editing">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'visibility')">
        
        <strong class="avenir-bold mb-1 d-block"><feather-icon icon="Edit2Icon" class="mr-05"></feather-icon>{{$t('creator.visibilityStep')}}</strong>
        <p class="avenir-medium m-0">{{ $t('creator.visibilityText') }}</p>
      </div>
    </b-col>
    <!-- ------------------------- -->

    <!-- attachments actions when attachments has a length ==== 0 -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && (casting_call.files.length === 0 && casting_call.links.length === 0)">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button class="add-option-action" variant="add-option-action" @click="$emit('edit_campaign', 'attachments')">
          <h4 class="avenir-bold">{{ $t('creator.files&Links') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.attachedFile') }}</p>
          <div class="box-add-option-absolute avenir-bold">
            {{ $t('creator.addAttachments') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit purple-edit-bubble" @click="$emit('edit_campaign', 'attachments')">
          <div>
            
            <strong class="avenir-bold mb-1 d-block"><b-icon icon="brightness-high" class="mr-05"></b-icon>{{ $t('creator.suggestAttachmentsTitle') }}</strong>
            <p class="avenir-medium m-0">{{ $t('creator.suggestAttachments') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ------------------------------------------ -->

    <!-- Attachments when has a length > 0 -->
    <div 
      :class="`${is_avaible_to_editing ? 'col-12 col-md-8' : 'col-12'}`" 
      class="w-100 text-left mb-1 container-attachments-links p-0" 
      v-if="casting_call.files.length > 0 || casting_call.links.length > 0"
    >
      <h2>{{ $t('creator.files&Links') }}</h2>
      <p class="avenir-medium">{{ $t('creator.attachedFile') }}</p>
      <b-row :class="`m-0 grid-files-links-preview ${is_sidebar ? 'no-grid' : ''}`">
        <div class="card-download-files-preview" v-for="file in casting_call.files" :key="file.uuid" @click="downloadFile(file)">
          <div class="d-flex align-items-center">
            <b-avatar 
            v-if="file.file ? !isVideo(file.file) : !isVideo(file.asset_url)"
              :src="imgOrvid(file)"
              :text="txtExt(imgOrvid(file))"
              square 
              class="avatar-rounded-files-preview"
            ></b-avatar>
            <video 
              v-else 
              :src="imgOrvid(file)"
              square 
              class="avatar-rounded-files-preview" 
              icon="camera-video"
            ></video>
            <div>
              <b-badge variant="light-secondary-v2" class="light-secondary-v2">{{$t('creator.asset_type').find((asset) => asset.value === file.type_file).text}}</b-badge>
            </div>
          </div>
          <div class="border p-1">
            <feather-icon class="text-dark" icon="DownloadIcon"></feather-icon>
          </div>
        </div>

        <a class="card-download-files-preview d-flex justify-content-between" v-for="link in casting_call.links" :key="link.uuid" :href="link.link" target="_blank">
          <div class="d-flex align-items-center col-3 pl-0" v-if="link.type_link">
            <b-avatar variant="light-secondary" icon="link45deg" square class="avatar-rounded-files-preview"></b-avatar>
            <div>
              <b-badge variant="light-secondary-v2" class="light-secondary-v2">{{$t('creator.asset_type').find((asset) => asset.value === link.type_link).text}}</b-badge>
              <span class="title-file-preview text-dark">{{link.link.trim()}}</span>
            </div>
          </div>
          <div class="border p-1">
            <feather-icon class="text-dark" icon="ExternalLinkIcon"></feather-icon>
          </div>
        </a>
      </b-row>

      <div class="background-button-edit" @click="$emit('edit_campaign', 'attachments')" v-if="is_avaible_to_editing">
        <h5 class="text-white animation-opacity-words">{{$t('creator.editAttachments')}}</h5>
      </div>
    </div>

    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-1" v-if="is_avaible_to_editing && (casting_call.files.length > 0 || casting_call.links.length > 0)">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'attachments')">        
        <strong class="avenir-bold d-block"><feather-icon icon="Edit2Icon" class="mr-05"></feather-icon>{{$t('creator.files&Links')}}</strong>
      </div>
    </b-col>
    <!-- ----------------------------- -->


    <!-- questions actions when questions has a length ==== 0 -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && casting_call.questions.length === 0">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button class="add-option-action" variant="add-option-action" @click="$emit('edit_campaign', 'questions')">
          <h4 class="avenir-bold">{{ $t('creator.informationRequest') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.aditionalQuestion') }}</p>
          <div class="box-add-option-absolute avenir-bold">
            {{ $t('creator.addRequest') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit purple-edit-bubble" @click="$emit('edit_campaign', 'questions')">
          <div>
            <strong class="avenir-bold mb-1 d-block"><b-icon icon="brightness-high" class="mr-05"></b-icon>{{ $t('creator.suggestQuestionsTitle') }}</strong>
            <p class="avenir-medium m-0">{{ $t('creator.suggestQuestions') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ------------------------------------------ -->

    <!-- Questions when has a length > 0  -->
    <div 
      class="w-100 text-left container-questions-campaign p-0" 
      :class="`${is_avaible_to_editing ? 'col-12 col-md-8' : 'col-12'}`" 
      v-if="casting_call.questions.length > 0"
    >
      <h2>{{ $t('creator.informationRequest') }}</h2>
      <p class="avenir-medium">{{ $t('creator.aditionalQuestion') }}</p>

      <b-col class="card-radion-button-steps col-12 mb-1 w-100" 
        v-for="(request, index) in casting_call.questions" 
        :key="index" v-b-toggle="`collapse_${index}`" 
        @click="request.type_question === 'radio' || request.type_question === 'checkbox' ? clickCollapse(index) : ''"
      >
        <div class="text-left text-gray-600">
          <div class="d-flex justify-content-between">
            <b-icon :icon="getIconsRequest(request.type_question)"  :class="{'icon-open': selected_questions.includes(index), 'space-icon-question': true}"></b-icon>
            <strong :class="{'icon-open': selected_questions.includes(index), 'w-100 d-block text-description': true}">{{index + 1}}. {{request.question}}</strong>
            <div class="container-plus-less-icon" v-if="request.options.length > 0" :ref="`icon_plus_${index}`">
              <span></span>
              <span></span>
            </div>
          </div>
          <b-collapse :id="`collapse_${index}`" class="pl-3">
            <div v-for="(option, index) in request.options" :key="index">
              <strong>{{String.fromCharCode(index + 65)}}.</strong>
              <span class="avenir-medium">  {{option}}</span>
            </div>
          </b-collapse>
        </div>
      </b-col>

      <div class="background-button-edit" @click="$emit('edit_campaign', 'questions')" v-if="is_avaible_to_editing">
        <h5 class="text-white animation-opacity-words">{{$t('creator.editSurvey')}}</h5>
      </div>
    </div>

    <b-col class="d-none d-md-block col-4 p-0 pl-2 mb-1" v-if="is_avaible_to_editing && casting_call.questions.length > 0">
      <div class="bubble-edit" @click="$emit('edit_campaign', 'questions')">        
        <strong class="avenir-bold mb-1 d-block"><feather-icon icon="Edit2Icon" class="mr-05"></feather-icon>{{$t('creator.surveyStep')}}</strong>
        <p class="avenir-medium m-0">{{ $t('creator.hoverSurvey') }}</p>
      </div>
    </b-col>
    <!-- ------------------------------- -->
  </div>

</template>

<script>
import {
  BImg,
  BAvatar,
  BBadge,
  BCol,
  BRow,
  BCollapse,
  VBToggle,
  BButton,
} from 'bootstrap-vue';
import { IconTypeCompetationGreen, getBadgeNetwork, getIconWhite } from '@/libs/utils/icons';
import { getDatev2 } from '@/libs/utils/times';
import { verifyAsset } from '@/libs/utils/urls';
import { numberFormat, isVideo } from '@/libs/utils/formats';


export default {
  directives: {
    'b-toggle': VBToggle
  },
  name: 'bodyPreviewCastingCall',
  components: {
    BImg,
    BAvatar,
    BBadge,
    BCollapse,
    BCol,
    BRow,
    BButton,
  },
  props: {
    casting_call: {
      type: Object,
    },
    is_sidebar: {
      type: Boolean,
      default: false,
    },
    is_avaible_to_editing: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isVideo,
      getBadgeNetwork,
      IconTypeCompetationGreen,
      getIconWhite,
      numberFormat,
      verifyAsset,
      getDatev2,
      selected_questions: [],
      doc_ext: [".pdf", ".docx", ".xlsx", ".pptx", ".csv", ".txt", ".rtf"],
      img_logo: this.casting_call.brand.logo_url ? verifyAsset(this.casting_call.brand.logo_url) : verifyAsset(this.casting_call.brand.logo),
    }
  },
  computed: {
    type_of_service() {
      return this.$t('creator.type_of_service').find((t) => t.value === this.casting_call.content_type).text
    }
  },
  methods: {
    imgOrvid(filename) {
      if (filename.file) {
        if (filename.file.includes(this.doc_ext)) {
          return '';
        } else {
          return verifyAsset(filename.file);
        }
      } else {
        return verifyAsset(filename.asset_url);
      }
    },
    txtExt(filename) {
      return this.doc_ext.find(ext => filename.includes(ext)) || "";
    },
    clickCollapse(index) {
      const container_icon = this.$refs[`icon_plus_${index}`][0];
      container_icon.classList.toggle("transition-icon-plus");
      container_icon.classList.toggle("transition-form");

      const is_selected = this.selected_questions.includes(index);

      if (!is_selected) {
        this.selected_questions.push(index);
      } else {
        const selected_index = this.selected_questions.indexOf(index);
        this.selected_questions.splice(selected_index, 1);
      }
    },
    searchByService() {
      this.$router.push({name: 'casting_calls', query: {content_type: this.casting_call.content_type}});
      this.$root.$emit('quit_navbar_white');
    },
    downloadFile(file) {
      const url = file.asset_url.length > 0 ? verifyAsset(file.asset_url) : verifyAsset(file.file);
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const splitted = url.split('/');
          link.download = splitted[splitted.length - 1];
          link.click();
        }
      };
      xhr.onerror = () => {
        if (xhr.status === 0)
          window.open(url, '_blank');
      };
      xhr.send();
    },
    getTitlePlatforms() {
      const types = {
        'influencer_marketing': this.$t('creator.requiredPlatforms'),
        'job_listing': this.$t('creator.typeWork'),
        'work_for_hire': this.$t('creator.requiredTalents'),
      }
      return types[this.casting_call.content_type];
    },
    getCompensation(compensation_value) {
      return this.$t('creator.compensation_type').find((c) => c.value === compensation_value)
    },
    getIconsRequest(type) {
      if (type === 'free_text') return 'textarea-t';
      if (type === 'radio') return 'ui-radios';
      if (type === 'checkbox') return 'ui-checks';
      if (type === 'media') return 'file-earmark-arrow-up';
    },
    convertUrlsToLinks(text) {
      const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;
      if (htmlTagPattern.test(text)) {
        return text;
      }
      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
      return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    },
  }
}
</script>
<style scoped lang="scss">
.img-network {
  margin-right: 0.5em;
  width: 16px;
}
.icon-svg {
  width: 18px;
  margin: 0px 3px 3px 0px
}
.icon-open {
  color: #4d95b0;
}
.text-description-preview {
  text-align: left;
  // margin-top: 50px;
  // margin-block: 70px;
}
.badge i, .badge svg {
  height: 19px;
  width: 22px;
  padding: 2px;
  font-size: 18px;
  stroke-width: 3px;
  vertical-align: sub;
}

.text-type-service {
  color: #3b8dab;
}
.container-plus-less-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  border: 0;
  position: relative;
}

.container-plus-less-icon span {
  position: absolute;
  transition: 300ms;
  background: #999999;
}
.container-plus-less-icon span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}
.container-plus-less-icon span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}
.transition-icon-plus span {
  transform: rotate(90deg);
}
.transition-form span:last-child {
  left: 50%;
  right: 50%;
}
.word-break {
  white-space: pre-line;
  word-wrap: break-word;
  text-align: justify;
  font-family: "Proxima Nova",-apple-system,Roboto,Arial,sans-;
  color: #666;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}
.text-description {
  word-wrap: break-word;
  text-align: justify;
}
.width-initial-parent {
  width: inherit;
}
.title-file-preview {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.mt-02 {
  margin-top: 0.2em;
}
.grid-files-links-preview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.no-grid {
  display: flex !important; 
  flex-direction: column;
}
.card-download-files-preview {
  border: 0.0725rem solid #ccc; 
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex-wrap: wrap;
}
.card-download-files-preview:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  transition: all 300ms;
}
.avatar-rounded-files-preview {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 1em !important;
  margin-right: 1em;
  background-color: #e8e8e8;
  color: #718096;
}
.light-secondary-v2 {
  background-color: #e8e8e8;
  color: #718096;
  font-weight: 400;
  font-size: small;
  padding: 8px;
}
.badge-compensation {
  background-color: rgba(110,205,115,0.1);;
  color: #255E55;
  font-weight: 400;
  font-size: small;
  padding: 8px;
}
.badge-networks {
  font-size: small;
  padding: 8px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
}
.space-icon-question {
  margin-right: 0.5em;
  margin-top: 2.5px;
}
.card-radion-button-steps {
  border-radius: 1em !important;
  background-color: white;
  overflow: hidden !important;
  padding: 1em;
  border: 0.0625rem solid rgba(222,226,230,.7); 
  position: relative;
}
@media(max-width: 700px) {
  .grid-files-links-preview {
    grid-template-columns: repeat(1, 1fr);
  }
}
.outline-secondary-badge {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  padding: 8px;
}
.ml-05 {
  margin-left: 0.5em;
}
.mr-05 {
  margin-right: 0.5em;
}
.animation-opacity-words {
  opacity: 0;
}
.background-button-edit {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 0.55em;
  z-index: 10;
}

.container-brand-category:hover .background-button-edit,
.text-description-preview:hover .background-button-edit,
.container-platforms-category:hover .background-button-edit,
.container-deadline-campaign:hover .background-button-edit,
.container-compensation-campaign:hover .background-button-edit,
.container-attachments-links:hover .background-button-edit,
.container-questions-campaign:hover .background-button-edit
{
  background-color: #3b8da888;
  transition: all 500ms;
  cursor: pointer;
  backdrop-filter: blur(10px);
}
.container-brand-category:hover .animation-opacity-words,
.text-description-preview:hover .animation-opacity-words,
.container-platforms-category:hover .animation-opacity-words,
.container-deadline-campaign:hover .animation-opacity-words,
.container-compensation-campaign:hover .animation-opacity-words,
.container-attachments-links:hover .animation-opacity-words,
.container-questions-campaign:hover .animation-opacity-words

{
  opacity: 1;
  transition: all 500ms;
}


.container-brand-category:not(:hover) .background-button-edit,
.container-brand-category:not(:hover) .animation-opacity-words,
.text-description-preview:not(:hover) .background-button-edit,
.text-description-preview:not(:hover) .animation-opacity-words,
.container-platforms-category:not(:hover) .background-button-edit,
.container-platforms-category:not(:hover) .animation-opacity-words,
.container-deadline-campaign:not(:hover) .background-button-edit,
.container-deadline-campaign:not(:hover) .animation-opacity-words,
.container-compensation-campaign:not(:hover) .animation-opacity-words,
.container-compensation-campaign:not(:hover) .background-button-edit,
.container-attachments-links:not(:hover) .animation-opacity-words,
.container-attachments-links:not(:hover) .background-button-edit,
.container-questions-campaign:not(:hover) .animation-opacity-words,
.container-questions-campaign:not(:hover) .background-button-edit
{
  transition: all 500ms;
}

.add-option-action {
  width: 100%;
  text-align: left;
  border: 2px dashed #777777aa;
  color: #777777aa;
  border-radius: 1em; 
  position: relative;

  &h4 {
    color: #777777aa;
  }

  &:hover {
    border: 2px solid #902875;
    transition: all 500ms;
  }
}
.blue-version {
  border-color: #2969C8 !important;
  color: #2969C8;
}

.box-add-option-absolute {
  background-color: #902875;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.blue-box-option {
  background-color: #2969C8;
}
.add-option-action:hover .box-add-option-absolute {
  transition: all 500ms;
  opacity: 1;
}
.add-option-action:not(:hover) .box-add-option-absolute,
.add-option-action:not(:hover),
.add-deal-action:not(:hover)  {
  transition: all 500ms;
}

.bubble-edit {
  text-align: left;
  padding: 1em;
  color: #3b8DA8;
  background: #E6f2f6;
  border-radius: 1em;
  cursor: pointer;
  position: sticky;
  position: -webkit-sticky;
  top: 12em;

  &:hover {
    transition: all 300ms;
    background: #3b8da82f;

    &:before {
      border: 10px solid #3b8da82f;
      content: '';
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
      position: absolute;
      transform: rotate(90deg);
      left: -20px;
      transition: all 300ms;
    }
  }

  &:before {
    border: 10px solid #E6f2f6;
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    transform: rotate(90deg);
    left: -20px;
  }
}

.purple-edit-bubble {
  height: 100%;
  background-color: #EEDFEA !important;
  color: #902875 !important;

  &:before {
    border: 10px solid #EEDFEA !important;
    content: '' !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    position: absolute !important;
    transform: rotate(90deg) !important;
    left: -20px !important; 
  }
  &:hover {
    background-color: #eed9e8 !important;
  }
}
.blue-edit-bubble {
  height: 100%;
  background-color: #2969c82c !important;
  color: #2969C8 !important;

  &:before {
    border: 10px solid #2969c82c !important;
    content: '' !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    position: absolute !important;
    transform: rotate(90deg) !important;
    left: -20px !important; 
  }
  &:hover {
    background-color: #2969c82c !important;
  }
}
</style>