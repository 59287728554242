<template>
  <div>
    <loader-video-brandme v-if="show_loader_video_brandme"></loader-video-brandme>
    <b-row class="max-width-1400">
      <b-col class="p-0" v-if="showEdit">
        <b-alert 
          :class="`col-12 p-1 text-left ${getVariantStatus()}`"
          show
          v-if="(is_your_proposal && proposal.state !== 'published') || (is_your_proposal) && is_editing"
        >
          <b-row class="m-0 w-100">
            <div class="avenir-medium d-flex justify-content-between align-items-center">
              <b-icon icon="exclamation-circle-fill" class="mr-05" font-scale="1.3"></b-icon>
              <div v-if="proposal.state !== 'in_review' && proposal.state !== 'changes_required'">
                {{ $t('creator.previewDescrip1') }}
              </div>
              <div v-else-if="proposal.state === 'in_review'">
                <span class="d-block avenir-bold">{{ $t('creator.proposalSumited') }}</span>
                <span>{{ $t('creator.proposalSumitDesc') }}</span>
              </div>
              <div v-else-if="proposal.state === 'changes_required'">
                <span class="d-block avenir-bold">Admin:</span>
                <span>{{proposal.changes_admin}}</span>
              </div>
            </div>
          </b-row>
        </b-alert>
      </b-col>
    </b-row>
    <div :class="class_dad">
      <b-col class="col-12 p-0 max-width-1400 text-center" v-if="loaded">
        <div class="d-flex">
          <header-preview-steps
            :data_header="{
              images: proposal.images,
              title: proposal.title,
              description: proposal.description,
              work_type: proposal.work_type,
              content_type: proposal.content_type,
              user_network: proposal.user_network,
              state: proposal.state,
              is_your_proposal: is_your_proposal,
              is_editing: is_editing,
              loaded_ia_description: loaded_ia_description,
              loaded_ia_title: loaded_ia_title,
              uploaded_images: uploaded_images,
              is_in_sidebar
            }"
            :is_free_proposal="is_free_proposal"
            @edit_proposal="editProposal"
            class="col-12 p-0"
            :key="update_header_previews"
          />
          <!-- Resume of proposal -->
          <b-col :class="`${is_in_sidebar ? 'd-none' : 'd-none d-lg-block col-lg-4 position-proposal'}`" v-if="proposal.state === 'published' && !is_editing">
            <div class="d-flex justify-content-center" v-if="!is_in_sidebar">
              <b-link @click="$router.push({name: 'user_profile', params: {user: vanity_name}})">
                <b-avatar size="55px" :src="verifyAsset(proposal.user.avatar)"></b-avatar>
              </b-link>
              <div class="ml-1 text-left">
                <strong class="mr-2 cursor-pointer" @click="redirectVanity()">{{proposal.user.first_name}} {{proposal.user.last_name}}<b-badge pill :class="getColorPlan(string_key)" :variant="getColorPlan(string_key)" v-if="string_key"> <span class="size-text-pro-proposal">{{ string_key }}</span></b-badge></strong>
                <div v-if="proposal.agency" class="d-flex align-items-center mt-1">
                  <b-link v-if="proposal.agency.url" :href="proposal.agency.url" target="_blank" class="mr-1 text-dark">Vía {{proposal.agency.name}}</b-link>
                  <span class="mr-1" v-else>Vía {{proposal.agency.name}}</span>
                  <b-avatar size="30px" :src="verifyAsset(proposal.agency.image)"/>
                </div>
                <div class="message-contact">
                  <p class="avenir-medium">{{ $t('creator.anyQuestions') }}</p>
                  <b-button variant="primary" @click="messageAction" v-if="!is_your_proposal" class="mb-1 mr-1">{{(!is_authenticated) ? $t('creator.loginMessage') : $t('creator.messageMe')}}</b-button>
                  <b-button variant="light" class="mb-1" v-if="vanity_name" @click="redirectVanity()">{{ $t('creator.publicProfileCasting') }}</b-button>
                </div>
              </div>
            </div>
          
            <div class="list-overview list-checkout d-none d-lg-block">
              <h5 class="border-bottom pb-1">{{ $t('creator.listingOverview') }}</h5>
              <div class="w-100 text-left text-secondary avenir-medium mb-05">{{ $t('creator.previewDeal') }}</div>
              <div class="d-flex justify-content-between m-0">
                <div class="d-flex mb-2 mr-2 text-wrap text-left avenir-bold">
                  {{deal_selected.title}}
                </div>
                <div class="d-flex align-items-left ml-auto avenir-bold">
                 <span v-if="!deal_selected.is_brandme_talent" class="d-flex text-nowrap">$ {{separatebycomma(deal_selected.price)}} USD</span>
                 <span v-else class="d-flex text-nowrap">
                  <span v-for="index in deal_selected.range_price" :key="index">$</span>
                  </span>
                </div>
              </div>
              <p class="d-flex flex-wrap">
                <span class="d-flex align-items-center mr-1">
                  <feather-icon icon="CalendarIcon" class="mr-05"/>
                  {{deal_selected.days_delivery}} <span class="avenir-medium ml-02">{{ $t('creator.daysDelivery') }}</span>
                </span>
                <span class="d-flex align-items-center mr-1">
                  <feather-icon icon="RepeatIcon" class="mr-05"/>
                  {{deal_selected.revision}} <span class="avenir-medium ml-02">{{ $t('creator.dealRevision') }}</span>
                </span>
                <span class="d-flex align-items-center mr-1">
                  <b-icon icon="shield-check" class="mr-05"/>
                  <span class="avenir-medium ml-02">{{$t('creator.licensing').find((licence) => licence.value === deal_selected.licensing).text}}</span>
                </span>
              </p>
              <div v-if="checked_options.length > 0">
                <hr class="mb-1"/>
                <div class="w-100 text-left text-secondary avenir-medium">{{ $t('creator.optionsStep').toUpperCase() }}</div>
                <div v-for="(option, index) in checked_options" :key="index">
                  <div class="d-flex justify-content-between py-1 border-bottom">
                    <div class="d-flex flex-column mr-2 text-wrap text-left">
                      <p>{{ option.title }}</p>
                      <span class="d-flex align-items-center mr-1">
                        <feather-icon icon="CalendarIcon" class="mr-05"/>
                        {{option.days_delivery}} <span class="avenir-medium ml-02">{{ $t('creator.adicionalDays') }}</span>
                      </span>
                    </div>
                    <div class="d-flex flex-column align-items-start ml-auto">
                      <span class="text-primary mr-05 cursor-pointer font-weight-bold" @click="unselectOption(option)">{{ $t('creator.previewRemove') }}</span>
                      <span class="mt-1">$ {{ separatebycomma(option.price) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <p class="border-top"></p>
               <p v-if="apply_balance" class="d-flex justify-content-between m-0">
                <strong>
                  <span class="d-flex align-items-center text-primary">
                    {{ $t('creator.discountBalance') }}
                    <feather-icon icon="TrashIcon" class="ml-1 cursor-pointer text-danger" @click="deleteDiscount()"/>
                  </span>
                </strong>
                <strong class="text-primary"> $ {{numberFormat(balance_discount,true)}} USD </strong>
              </p>
              <p class="d-flex justify-content-between m-0 align-items-center">
                <strong>{{ $t('creator.dealSubtotal') }}</strong>
                <strong v-if="!deal_selected.is_brandme_talent">
                  $ {{ apply_balance ? numberFormat(total_discounted, true) : numberFormat(getTotal(), true) }} USD
                </strong>

                <strong v-else>
                  <span v-for="index in deal_selected.range_price" :key="index">$</span>
                </strong>
              </p>
              <p class="d-flex justify-content-between m-0">
                <strong>{{ $t('creator.deliveryTime') }}</strong>
                <strong>
                  <span class="d-flex align-items-center">
                    <feather-icon icon="CalendarIcon" class="mr-05"/>
                    {{getTotalDaysDelivery()}} {{ $t('creator.previewDays') }}
                  </span>
                </strong>
              </p>
              <p v-if="is_authenticated && !apply_balance" class="d-flex justify-content-between">
                <strong>{{ $t('creator.applyBalanceCheck') }} <b-icon icon="plus-circle" class="cursor-pointer text-primary" @click="view_change_balance = !view_change_balance"/></strong>
                <strong>{{ $t('creator.balance') }} $ {{numberFormat(getBalance,true)}} USD </strong>
              </p>
              <div class="col-12 mb-1" v-if="view_change_balance">
                <b-form-group>
                  <b-form-input 
                    v-model="balance_discount" 
                    :placeholder="$t('creator.balance')"
                    autocomplete="off"
                   @keypress="onlyNumbers"/>
                </b-form-group>

                <div class="d-flex justify-content-end mt-2">
                  <b-button
                   @click="applyDiscount()"
                   :disabled="!isDiscountValid()"
                    variant="primary">
                    {{ $t('creator.applyDiscount') }}
                  </b-button>
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center mt-1">
                <b-button 
                  :class="`${!buy ? 'btn-buy' : 'btn-hover'} mb-1 col-8`" 
                  @click="buyAction" 
                  :disabled="!!$route.query.uuid" 
                  v-if="!is_your_proposal"
                >
                  <span v-if="!buy">{{ (!is_authenticated) ? $t('creator.LoginToBuy') : (deal_selected.is_brandme_talent || (optionsHasBrandmeTalent()) ? 'Solicitar Precio' : $t('creator.buyNow')) }}</span><b-spinner small class="mt-0" v-if="buy"></b-spinner>
                </b-button>
              </div>
              <p class="text-left font-size-12" v-if="!is_your_proposal">
                <span>{{ $t('pricesInMXM') }}</span>
                <span class="avenir-black d-block">{{ $t('creator.clickBuy') }}</span>
                <span class="avenir-medium">
                  {{ $t('creator.refunded7days') }}
                </span>
              </p>
            </div>
          </b-col>
          <!-- ----------------- -->
        </div>
         
        <b-row class="d-flex m-0">
          <!-- CHECK HOW RESPONSE IN SIDEBAR CHAT -->
          <div :class="`${(proposal.state !== 'published' || is_editing) || is_sidebar ? 'col-12' : 'col-12 col-lg-8'} p-0`">
            <deals-options-requests  
              :deals="proposal.deals"
              :options="proposal.options"
              :requests="proposal.requests"
              :unchecked_option="unchecked_option"
              :loaded_ia="loaded_ia_deals"
              :is_in_sidebar_chat="is_in_sidebar"
              @deal_selected="dealSelected"
              @checked_options="checkedOptions"
              @reset_unchecked_option="resetUncheckedOption"
              @edit_proposal="editProposal"
              :is_avaible_to_editing="(is_your_proposal && proposal.state !== 'published') || (is_your_proposal) && is_editing"
            />
          </div>
          <b-col v-if="(proposal.state === 'published' && !is_in_sidebar) && Object.keys(results).length > 0" :class="`${(proposal.state !== 'published' || is_editing) || is_sidebar ? 'col-12' : 'col-12 col-lg-8'} p-0`">
            <span class="d-flex text-start avenir-medium mt-2 mb-3">{{  $t('creator.imgProperty') }}</span>
            <div class="text-proposals">{{ $t('creator.similarProposals') }}</div>
            <component :is="vue_horizontal" responsive class="horizontal my-2" :displacement="0.3" snap="center">
              <card-category 
                :redirect="true"
                v-for="(item, index) in results" :key="index"
                :proposal="item"
                :width="'300px'"
                :countries="countries"
                class="mr-1"
              />
            </component>
          </b-col>
        </b-row>
      </b-col>

    </div>
    <!-- Container fixed "Send to review" -->
    <div class="container-nav-fixed" v-if="((is_your_proposal && proposal.state !== 'published') && !is_in_sidebar) || (((is_your_proposal) && is_editing) || canEdit() && !is_in_sidebar)">
      <div v-if="canEdit()" class="d-flex align-items-center justify-content-end w-100 mr-2">
        <b-row>
        <b-form-input
          class="button-free-report"
          v-model="change"
          :placeholder="$t('creator.addComment')"
          />
        </b-row> 
        <b-button :disabled="change.length === 0" variant="danger" class="button-free-report ml-2 mr-1" @click="declineChangeAdmin">{{ $t('creator.decline') }}</b-button>
        <b-button class="button-free-report btn-approve" @click="approveChangeAdmin">{{ $t('creator.approve') }}</b-button>
      </div>
      
      <div v-else class="d-flex align-items-center justify-content-end w-100 mr-2">
        <b-button :disabled="stepsRequired" variant="blue-button-next-step" class="button-free-report blue-button-next-step" @click="submitPreview">{{ $t('creator.forReview') }}</b-button>
      </div>  
    </div>
    <!-- ------------------------------- -->

    <!-- Container fixed responsive actions buy/login to buy -->
    <div class="mt-2 container-nav-fixed" :class="{'d-lg-none': !is_in_sidebar}" v-else>
      <b-button class="btn-bag-shopping" variant="btn-bag-shopping" @click="modalBuy()">
        <div class="d-flex d-flex align-items-center">
          <feather-icon icon="ShoppingBagIcon" size="24" />
          <div class="ml-1">
            <span class="d-flex text-order">{{ $t('creator.orderSummary') }}</span>
            <span v-if="!deal_selected.is_brandme_talent">$ {{ separatebycomma(deal_selected.price) }}</span>
            <span v-else>
              <span v-for="index in deal_selected.range_price" :key="index">$</span>
            </span>
            •
            <span class="text-days">{{ deal_selected.days_delivery }} {{ $t('twitch.days') }}</span>
          </div>
        </div>
      </b-button>
      <b-button 
        :class="`${!buy ? 'btn-buy' : 'btn-hover'} col-4`" 
        @click="buyAction" 
        :disabled="!!$route.query.uuid" 
        v-if="!is_your_proposal"
      >
        <span v-if="!buy">{{ (!is_authenticated) ? $t('creator.LoginToBuy') : (deal_selected.is_brandme_talent || (optionsHasBrandmeTalent()) ? 'Solicitar Precio' : $t('creator.buyNow')) }}</span><b-spinner small class="mt-0" v-if="buy"></b-spinner> </b-button>
    </div>
    <!-- ------------------------------- -->
    <modal-login :open_modal="open_modal_login" :key="update_modal_login"></modal-login>
    <sidebar-edit-proposal
      v-if="open_sidebar_edit"
      class="open-sidebar-edit-proposal" 
      :open_sidebar_edit="open_sidebar_edit" 
      :key="update_sidebar_edit"
      :proposal="proposal"
      :section_dad="section_to_edit"
      :data_for_sidebar="data_for_sidebar"
      @update_proposal="updateProposal"
      @reset_proposal="resetProposal"
    ></sidebar-edit-proposal>

    <!-- MODAL BUYING RESPONSIVE -->
    <modal-brandme :show_modal="modal_buy" :key="change_modal" :closeIcon="'close-select'" :bodyClass="'p-0'" :padding="'p-0'">
      <template #body="">
        <div class="list-overview-modal list-checkout">
          <h5 class="border-bottom pb-1">{{ $t('creator.listingOverview') }}</h5>
          <div class="w-100 text-left text-secondary avenir-medium mb-05">{{ $t('creator.previewDeal') }}</div>
          <div class="d-flex justify-content-between m-0">
            <div class="d-flex mb-2 mr-2 text-wrap text-left avenir-bold">
              {{deal_selected.title}}
            </div>
            <div class="d-flex align-items-left ml-auto avenir-bold">
              <span v-if="!deal_selected.is_brandme_talent" class="d-flex text-nowrap">$ {{separatebycomma(deal_selected.price)}}</span>
            </div>
          </div>
          <p class="d-flex flex-wrap">
            <span class="d-flex align-items-center mr-1">
              <feather-icon icon="CalendarIcon" class="mr-05"/>
              {{deal_selected.days_delivery}} <span class="avenir-medium ml-02">{{ $t('creator.daysDelivery') }}</span>
            </span>
            <span class="d-flex align-items-center mr-1">
              <feather-icon icon="RepeatIcon" class="mr-05"/>
              {{deal_selected.revision}} <span class="avenir-medium ml-02">{{ $t('creator.dealRevision') }}</span>
            </span>
            <span class="d-flex align-items-center mr-1">
              <b-icon icon="shield-check" class="mr-05"/>
              <span class="avenir-medium ml-02">{{$t('creator.licensing').find((licence) => licence.value === deal_selected.licensing).text}}</span>
            </span>
          </p>
          <div v-if="checked_options.length > 0">
            <hr class="mb-1"/>
            <div class="w-100 text-left text-secondary avenir-medium">{{ $t('creator.optionsStep').toUpperCase() }}</div>
            <div v-for="(option, index) in checked_options" :key="index">
              <div class="d-flex justify-content-between py-1 border-bottom">
                <div class="d-flex flex-column mr-2 text-wrap text-left">
                  <p>{{ option.title }}</p>
                  <span class="d-flex align-items-center mr-1">
                    <feather-icon icon="CalendarIcon" class="mr-05"/>
                    {{option.days_delivery}} <span class="avenir-medium ml-02">{{ $t('creator.adicionalDays') }}</span>
                  </span>
                </div>
                <div class="d-flex flex-column align-items-start ml-auto">
                  <span class="text-primary mr-05 cursor-pointer font-weight-bold" @click="unselectOption(option)">{{ $t('creator.previewRemove') }}</span>
                  <span class="mt-1">$ {{ separatebycomma(option.price) }}</span>
                </div>
              </div>
            </div>
          </div>
          <p class="d-flex justify-content-between m-0 pt-1 border-top">
            <strong>{{ $t('creator.dealSubtotal') }}</strong>
            <strong>$ {{numberFormat(getTotal(), true)}}</strong>
          </p>
          <p class="d-flex justify-content-between">
            <strong>{{ $t('creator.deliveryTime') }}</strong>
            <strong>
              <span class="d-flex align-items-center">
                <feather-icon icon="CalendarIcon" class="mr-05"/>
                {{getTotalDaysDelivery()}} {{ $t('creator.previewDays') }}
              </span>
            </strong>
          </p>
          <div class="col-12 d-flex justify-content-center">
            <b-button 
              :class="`${!buy ? 'btn-buy' : 'btn-hover'} mb-1 col-8`" 
              @click="buyAction" 
              :disabled="!!$route.query.uuid" 
              v-if="!is_your_proposal"
            >
              <span v-if="!buy">{{ (!is_authenticated) ? $t('creator.LoginToBuy') : $t('creator.buyNow') }}</span><b-spinner small class="mt-0" v-if="buy"></b-spinner>
            </b-button>
          </div>
          <p class="text-left font-size-12" v-if="!is_your_proposal">
            <span class="avenir-black d-block">{{ $t('creator.clickBuy') }}</span>
            <span class="avenir-medium">
              {{ $t('creator.refunded7days') }}
            </span>
          </p>
        </div>
      </template>
    </modal-brandme>
    <!-- --------------------------------------------------------------------- -->
  </div>
</template>

<script>
import {
  BIcon,
  BImg,
  BFormInput,
  BSpinner,
  BRow,
  BCol,
  BButton,
  BLink,
  BAvatar,
  BAlert,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import cardCategory from "@/views/pages/proposals/cardCategory.vue";
import { verifyAsset } from '@/libs/utils/urls'
import { separatebycomma, numberFormat } from '@/libs/utils/formats'
import { getUserData } from '@/libs/utils/user';
import { createDescription, createTitle } from '@/services/ia';
import profile_services from '@/services/profile';
import chat_services from '@/services/chat'
import { getColorPlan } from '@/libs/utils/icons';
import { getCountries, showConfetti } from '@/libs/utils/others';
import LoaderVideoBrandme from '@/views/components/banner/LoaderVideoBrandme.vue'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');


export default {
  name: 'preview',
  components: {
    BFormCheckbox,
    BIcon,
    BImg,
    BFormInput,
    BSpinner,
    cardCategory,
    BRow,   
    BCol,
    BButton,
    BLink,
    BAvatar,
    BAlert,
    BBadge,
    LoaderVideoBrandme,
    ModalBrandme: () => import('@/views/components/modal/ModalBrandme.vue'),
    headerPreviewSteps: () => import('@/views/components/proposals/headerPreviewSteps.vue'),
    dealsOptionsRequests: () => import('@/views/components/proposals/dealsOptionsRequests.vue'),
    sidebarEditProposal: () => import('./sidebarEditProposal.vue'),
    ModalLogin: () => import('@/views/components/modal/ModalLogin.vue'),
  },
  data() {
    return {
      apply_discount: false,
      total_discounted: 0,
      apply_balance: false,
      view_change_balance: false,
      balance_discount: "",
      verifyAsset,
      separatebycomma,
      numberFormat,
      getColorPlan,
      countries: getCountries(),
      buy: false,
      modal_buy: false,
      change_modal: 0,
      change: '',
      vanity_name: '',
      results: {},
      loaded: false,
      open_edit_modal: false,
      update_edit_modal: false,
      deal_selected: {
        title: '',
        price: 0,
        days_delivery: 0,
        revision: 0,
        licensing: 'commercial_use',
      },
      checked_options: [],
      unchecked_option: '',
      can_send_review: false,
      proposal: {},
      is_your_proposal: undefined,
      open_modal_login: false,
      update_modal_login: false,
      user: getUserData(),
      string_key: null,
      open_sidebar_edit: false,
      update_sidebar_edit: 0,
      update_header_previews: 0,
      section_to_edit: '',
      data_for_sidebar: null,
      vue_horizontal: null,
      loaded_ia_deals: false,
      loaded_ia_title: false,
      loaded_ia_description: false,
      uploaded_images: false,
      is_free_proposal: true,
    }
  },
  metaInfo() {
    return {
      title: `Colabora con: ${this.proposal.title}`,
      meta: [
        { vmid: "og:title", property: 'og:title', content: `Colabora con: ${this.proposal.title}` },
        { vmid: "og:image",  property: 'og:image',  content: this.proposal.open_graph_image ? this.proposal.open_graph_image : (this.proposal.images[0].preview ? this.proposal.images[0].preview : this.proposal.images[0].media)},
        { vmid: "og:description", property: 'og:description', content: this.proposal.description.slice(0, 50) },
        { vmid: "twitter:title", name: 'twitter:title', content: `Colabora con: ${this.proposal.title}` },
        { vmid: "twitter:image", name: 'twitter:image', content: this.proposal.open_graph_image ? this.proposal.open_graph_image : (this.proposal.images[0].preview ? this.proposal.images[0].preview : this.proposal.images[0].media)},
        { vmid: "twitter:description", name: 'twitter:description', content: this.proposal.description.slice(0, 50) },
      ]
    };
  },
  
  created() {
    this.getData();
    if (this.$route.query.uuid && !this.is_sidebar) {
      proposals_services.statusCheckoutProposal(this.$route.query.uuid).then((response) => {
        if (response.status >= 400) this.$router.push({name: 'dashboard-analytics'})
        this.$router.push({name: 'chat-proposal', params: {chat_uuid: response.chat_uuid}})
      });
    }
    this.$root.$on('donwload_examples', (data) => {
      this.proposal.images, this.$route.params.proposal.images = data.body.detail.images;
      this.update_header_previews += 1;
      this.uploaded_images = false;
    });

    this.$root.$on('proposals_ws_ia', (data) => {
      if (data.type === 'title_proposal' && this.proposal.uuid === data.body.uuid) {
        this.proposal.title = data.body.title;
        this.$route.params.proposal.title = data.body.title

        window.history.pushState({}, null, `/view-proposal/${data.body.slug}`);
        this.loaded_ia_title = false;
        this.getDescriptionProposalIa(this.proposal.uuid, 'propuesta', this.proposal.title, 1950);
      } else if (data.type === 'description_created' && this.proposal.uuid === data.body.uuid) {

        this.proposal = data.body;
        this.$route.params.proposal = data.body;
        
        (data.body.type_request === 'oferta') ? this.loaded_ia_deals = false : this.loaded_ia_description = false;
      }
    });
    this.$root.$on('proposal_updated_ws', (data) => {
      if (data.body.uuid === this.proposal.uuid) {
        this.proposal = data.body;
        this.$route.params.proposal = data.body;
      }
    });
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default;
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    const header_navbar_shadow = document.getElementsByClassName('header-navbar-shadow')[0];
    header_navbar_shadow.removeAttribute('style');
    document.title = 'BrandMe';
  },
  props: {
    showEdit: {
      type: Boolean,
      default: true
    },
    proposal_dad: {
      type: Object,
      default: () => {
        return {}
      }
    },
    is_sidebar: {
      type: Boolean,
      default: false,
    },
    class_dad: {
      type: String,
      default: '',
    }
  },
  computed: {
    getBalance() {
      if (getUserData()) return getUserData().balance
      return 0
    },
    is_in_sidebar() {
      return Object.keys(this.proposal_dad).length > 0;
    },
    is_authenticated() {
      return getUserData()
    },
    is_editing() {
      return this.$route.params.is_editing ? this.$route.params.is_editing : false
    },
    stepsRequired() {
      if (this.can_send_review) {
        return !(
          this.proposal.work_type &&
          this.proposal.content_type &&
          this.proposal.title && 
          this.proposal.description && 
          this.proposal.deals.length > 0 &&
          this.proposal.images.length > 0
        );
      } else {
        return true;
      }
    },
    show_loader_video_brandme() {
      return !!this.$route.params.load_ia && this.proposal.description.length === 0 && this.uploaded_images;
    }
  },
  methods: {
    deleteDiscount() {
      this.apply_balance = false;
      this.balance_discount = ""
    },
    applyDiscount() {
      if (this.isDiscountValid()) {
        this.total_discounted = this.getTotal() - this.balance_discount;
        this.apply_balance = true;
        this.apply_discount = true
        this.view_change_balance = false
      }
    },
    onlyNumbers(event) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
    isDiscountValid() {
      return (
        this.balance_discount >= 1 &&
        this.balance_discount <= this.getBalance &&
        this.balance_discount <= this.getTotal()
      );
    },
    redirectVanity() {
      if (this.vanity_name) {
        this.$router.push({ name: 'user_profile', params: { user: this.vanity_name } });
      }
    },
    modalBuy() {
      this.modal_buy = true,
      this.change_modal += 1
    },
    resetProposal(proposal) {
      this.proposal = proposal;
      this.checkFieldsForIA(true);
    },
    updateProposal(proposal) {
      this.proposal = proposal;
      this.$route.params.proposal = proposal;
      this.update_header_previews += 1;
    },
    editProposal(section, data) {
      this.section_to_edit = section;
      this.data_for_sidebar = data;
      this.open_sidebar_edit = true; this.update_sidebar_edit += 1;
    },
    declineChangeAdmin() {
      proposals_services.changeAdmin(this.proposal.uuid, {status: 'changes_required', changes: this.change}).then((response) => {
        if (response) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              position: 'top-right',
              props: {
                title: this.$t('creator.titleUnpublished'),
                icon: "BellIcon",
                variant: "success",
                text: this.$t('creator.textChange'),
              },  
            })
          })
        }
        this.$router.push({name: 'my_work'});
      })  
    },
    approveChangeAdmin() {
      proposals_services.changeAdmin(this.proposal.uuid, {status: 'published'}).then((response) => {
        if (response) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              position: "top-right",
              props: {
                title: this.$t('creator.titleUnpublished'),
                icon: "BellIcon",
                variant: "success",
                text: this.$t('creator.textApprove'),
              },  
            });
          })
        }
        this.$router.push({name: 'my_work'});
      })  
    },
    messageAction() {
      if (!this.is_authenticated) {
        this.open_modal_login = true;
        this.update_modal_login = !this.update_modal_login;
      } else {
        proposals_services.getChatRoomProposals(this.proposal.uuid, this.proposal.user_network.user, getUserData().id).then((response) => {
          this.$router.push({name: 'chat-proposal', params: {chat_uuid: response.uuid}});
        });
      }
    },
    optionsHasBrandmeTalent() {
      let has_brandme_talent = false;
      this.checked_options.forEach(op => {
        if (op.is_brandme_talent) has_brandme_talent = true;
      })
      return has_brandme_talent
    },
    buyAction() {
      let has_brandme_talent = false;
      this.checked_options.forEach(op => {
        if (op.is_brandme_talent) has_brandme_talent = true;
      })
      if (!this.is_authenticated) {
        this.open_modal_login = true;
        this.update_modal_login = !this.update_modal_login;
      } else if (this.deal_selected.is_brandme_talent || has_brandme_talent) {
        this.buy = true
        const data = {
          'proposal_uuid': this.proposal.uuid,
          'deal_uuid': this.deal_selected.uuid,
          'options': this.checked_options.map((item) => item.uuid)
        }

        chat_services.createChatBrandmeTalent(data).then(response => {
          if (this.$route.name === 'chat-proposal') {
            this.buy = false
            const params = this.$route.params
            if (params.chat_uuid === response.uuid) {
              loadToastificationContent().then((module) => {
                this.$toast({
                  component: module.default,
                  position: "top-right",
                  props: {
                    title: 'Te encuentras dentro del chat donde podrás solicitar el precio',
                    icon: "BellIcon",
                    variant: "success",
                    text: 'El chat se activará una vez que el Talento responda',
                  },
                });
              })
            } else {
              this.$router.push({name: 'chat-proposal', params: {chat_uuid: response.uuid}});
            }
          } else {
            this.$router.push({name: 'chat-proposal', params: {chat_uuid: response.uuid}});
          }
        })
      } else {
        this.buy = true;
        proposals_services.createCheckout({
          options: this.checked_options.map((item) => item.uuid),
          apply_balance: this.apply_balance,
          balance: this.apply_balance ? this.balance_discount : 0
        }, this.proposal.uuid, this.deal_selected.uuid).then((response) => {
          window.location.href = response.url;
        });
      }
    },
    canEdit() {
      if (this.user) {
        if (this.user.is_superuser) return true
        const has_permission_edit = this.user.user_permissions.find(per => per.codename === 'change_proposalsusernetwork')
        return !!has_permission_edit
      }
      return false;
    },
    resetUncheckedOption() {
      this.unchecked_option = '';
    },
    unselectOption(option) {
      this.unchecked_option = option.uuid;
      this.getTotal();
      this.applyDiscount();
    },
    dealSelected(deal) {
      this.deal_selected = deal;
      this.getTotal();
      this.applyDiscount();
    },
    checkedOptions(options) {
      this.checked_options = options;
      this.getTotal();
      this.applyDiscount();
    },
    getTotal() {
      let total = 0;
      this.checked_options.forEach((option) => {
        total += parseFloat(option.price);
      });
      return total + parseFloat(this.deal_selected.price);
    },
    getTotalDaysDelivery() {
      let total = 0;
      this.checked_options.forEach((option) => {
        total += option.days_delivery;
      });
      return total + this.deal_selected.days_delivery;
    },
    submitPreview() {
      if (this.proposal.state === 'published') {
        this.$swal({
          title: this.$t('creator.text_confirm_change_proposal'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('creator.text_confirm_button_change_proposal'),
          cancelButtonText: this.$t('search.cancel'),
          customClass: {
            confirmButton: 'btn blue-button mb-1',
            cancelButton: 'btn outline-blue ml-1'
          },
          buttonsStyling: false,
          heightAuto: false
        }).then(result => {
          if (result.value) {
            this.sendReviewAction();
          }
        });
      } else {
        this.sendReviewAction();
      }
    },
    sendReviewAction() {
      proposals_services.sendReview(this.proposal.uuid).then(response => {
        if (response.updated !== undefined) {
          if (response.updated || response.proposal.state === 'published') {
            loadToastificationContent().then((module) => {
              this.$toast({
                component: module.default,
                position: "top-right",
                props: {
                  title: this.$t('creator.proposal_in_review_title'),
                  icon: "BellIcon",
                  variant: "success",
                  text: this.$t('creator.proposal_in_review_text'),
                },
              });
            })
            showConfetti();
          } else {
            loadToastificationContent().then((module) => {
              this.$toast({
                component: module.default,
                position: "top-right",
                props: {
                  title: this.$t('campaigns.errormessage'),
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: this.$t('creator.proposal_in_review_error'),
                },
              });
            })
          }
        }
        this.$router.push({name: 'my_work'});
        localStorage.removeItem('steps');
      });
    },
    getData() {
      if (this.$route.params.slug) {
        this.proposal = this.$route.params.proposal;
        this.checkFieldsForIA(this.$route.params.load_ia);
        this.string_key = this.proposal.user_network.current_plan && this.proposal.user_network.current_plan.string_key ? this.proposal.user_network.current_plan.string_key : null;
        this.can_send_review = this.proposal.state === 'pending' || this.proposal.state === 'published' || this.proposal.state === 'changes_required';
        this.getSimilary();
        this.vanity_name = this.proposal.user_network ? this.proposal.user_network.vanity_name : ''
        this.loaded = true;
      } else {
        this.proposal = this.proposal_dad;
        this.loaded = true;
      }

      if (this.proposal?.user_network?.current_plan?.name) {
        this.is_free_proposal = this.proposal.user_network.current_plan.name.toLowerCase() === 'free';
      }
      this.isYourProposal();
    },
    getMediaContents(content) {
      return `https://klear.com/api/2.1/HostedContent/instagram_post?url=https://www.instagram.com/p/${content.basic.code}/`
    },
    isYourProposal() {
      if (this.user) this.is_your_proposal = this.proposal.user_network.user === this.user.id;
      else this.is_your_proposal = false
    },
    getSimilary() {
      const query = {
        work_types: [this.proposal.work_type],
      };
      proposals_services.searchProposals(query, 10).then((response) => {
        this.results = response.results.filter((l) => l.uuid !== this.proposal.uuid)
      });
    },
    getVariantStatus() {
      if (this.proposal.state !== 'in_review' && this.proposal.state !== 'changes_required') return 'secondary-alert-proposal';
      else if (this.proposal.state === 'in_review') return 'info-alert-proposal'
      else if (this.proposal.state === 'changes_required') return 'warning-alert-proposal'
    },
    getDescriptionProposalIa(uuid, type_description, title, length) {
      this.loaded_ia_description = true;
      const form_data = new FormData();
      form_data.append('type_description', type_description)
      form_data.append('title', title)
      form_data.append('length', length)
      form_data.append('uuid', uuid)
      createDescription(form_data).then(() => {})
    },
    checkFieldsForIA(load_ia) {
      const form_data = new FormData();
      if (this.proposal.title.length === 0) {
        this.loaded_ia_title = true;
        this.loaded_ia_description = true;

        form_data.append('user_network_uuid', this.proposal.user_network.uuid);
        form_data.append('category', this.proposal.work_type)
        form_data.append('sub_category', this.proposal.content_type)
        form_data.append('proposal_uuid', this.proposal.uuid)

        createTitle(form_data).then(() => {})
      } if (this.proposal.description.length === 0) {
        this.getDescriptionProposalIa(this.proposal.uuid, 'propuesta', this.proposal.title, 1950);
      } if ((this.proposal.deals.length > 0) && load_ia) {

        const title_deal = this.proposal.deals.length > 0 ? this.proposal.deals[0].title : this.$t(`dealsTitles.${this.proposal.work_type}_${this.proposal.content_type}`);
        this.getDescriptionProposalIa(this.proposal.deals[0].uuid, 'oferta', title_deal, 400);

      } if ((load_ia && this.proposal.images.length === 0) && this.proposal.user_network.network === 'instagram') {
        this.uploaded_images = true;
        profile_services.getMediaInstagram(this.proposal.user_network.username, 10, 'most_recent', null, '0').then((response)  => {
          if (response.results) {
            const form_data = new FormData();
            response.results.forEach((content) => {
              form_data.append('images_string', this.getMediaContents(content));
            });
  
            proposals_services.uploadImagesProposal(this.proposal.uuid, form_data).then(() => {});
          } else {
            this.uploaded_images = false;
          }
        }).catch(() => {
          this.uploaded_images = false;
        });
      }
      
    },
  },
}
</script>
<style scoped lang="scss">
.text-gray-600 {
  color: #6c757d !important;
}
.position-proposal {
  position: absolute;
    top: 33em;
    right: -1em;
}
.text-order {
  color:#495057;
  font-size: .745rem !important;
  margin-block: 4px;
}
.text-days {
  color:#495057;
  font-size: .945rem !important;
}
.btn-bag-shopping {
  border-color: #ced4da;
  font-size: 0.875rem;
  margin: 12px 0px 12px 0px;
  height: auto;
  padding: 10px;
}
.btn-bag-shopping:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.mb-05 {
  margin-bottom: 0.5em;
}
.ml-02 {
  margin-left: 0.2em;
}
.m-05 {
  margin-right: 5px;
}
.pb-10 {
  padding-bottom: 10em;
}
.btn-buy {
  height: 45px !important;
  padding: 4px 1em !important;
  background-color: #3483fa !important;
  border-color: transparent !important;

  @media(max-width: 700px) {
    width: auto !important;
    padding: 4px 1em !important;
  }
}
.btn-hover {
  background-color: #3483fa !important;
  border-color: transparent !important;
}
.btn-approve {
  background-color: #3483fa !important;
  border-color: transparent !important;
}
.btn-buy:hover {
  background-color: #2968c8 !important;
  border-color: transparent !important;
}
.text-proposals {
  width: 100%;
  text-align: left;
  font-size: 1.3rem;
  font-weight: bold;
}
  .max-width-1400 {
    max-width: 1430px;
    margin: 0px auto;
  }
  .font-size-12 {
    font-size: 12px;
    color: #6c757d;
  }
  .message-contact {
    margin: 1em 0 0 0;
    position: relative;
    height: auto;
    width: 100%;
    padding: 1em;
    background: #e9ecef;
    border-radius: 1em;
  }
  .message-contact:before {
    border: 10px solid #e9ecef;
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    top: 0px;
    left: -8px
  }
  .list-overview {
    padding: 1em;
    border: 1px solid #eee5e5;
    margin: 2em 0;
    border-radius: 1em;
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 12em;
  }
  .list-overview-modal {
    padding: 1em;
    border: 1px solid #eee5e5;
    border-radius: 1%;
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 12em;
  }

</style>
<style lang="scss">
.close-select {
  position:absolute;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
  bottom: 369px;
  right: 0px;
  z-index: 10;
}
.close-select:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.secondary-alert-proposal {
  background-color: #DEDEDF !important;
  color: #212529 !important;
}
.warning-alert-proposal {
  background-color: #f6e6e6 !important;
  color: #ab3b3b !important;
}
.info-alert-proposal {
  background-color: #e6f2f6 !important;
  color: #3B8DAB !important;
}
  .open-sidebar-edit-proposal > .b-sidebar-right {
    animation: sidebar-edit-proposal 500ms;
  }
  @keyframes sidebar-edit-proposal {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
.form-group-layout > label {
  text-align: left;
}
.btn-group-toggle {
  display:initial;
}
.container-nav-fixed {
  width: 100%;
  height: 80px;
  position: sticky;
  background-color: white;
  left: 0;
  bottom: 0;
  display: flex;
  padding: 0 0.8em;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  z-index: 10;

  @media(max-width: 500px) {
    padding: 0 1em;
  }
}
.button-free-report {
  height: auto;
}
.lists-edit-steps {
  padding: 1em;
  border-bottom: 1px solid #dedede;
  display: flex;
  justify-content: space-between;
}
.lists-edit-steps:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.disabled-list-edit {
  cursor: initial !important;
  color: #dedede;
}
.disabled-list-edit:hover {
  background-color: white;
}
.size-text-pro-proposal {
  font-size: 8.5px;
  text-transform: uppercase;
}
</style>